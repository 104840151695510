@font-face {
    font-family: 'AppFont';
    src: local('AppFont'), url("../src/fonts/app_font.otf") format('opentype');
}

@font-face {
    font-family: 'AppFontMedium';
    src: local('AppFontMedium'), url("../src/fonts/app_font_medium.otf") format('opentype');
}

@font-face {
    font-family: 'AppFontExtraBold';
    src: local('AppFontExtraBold'), url("../src/fonts/app_font_extra_bold.otf") format('opentype');
}

@font-face {
    font-family: 'AppFontBook';
    src: local('AppFontBook'), url("../src/fonts/app_font_book.otf") format('opentype');
}

@font-face {
    font-family: 'AppFontBold';
    src: local('AppFontBold'), url("../src/fonts/app_font_bold.otf") format('opentype');
}


.fontRegular {
    font-family: 'AppFont' !important;
  }
  
.fontMedium {
    font-family: 'AppFontMedium' !important;
}

.fontExtraBold {
    font-family: 'AppFontExtraBold' !important;
}
  
.fontBook {
    font-family: 'AppFontBook' !important;
}

.fontBold {
    font-family: 'AppFontBold' !important;
}

body, button, input, textarea, select {
    font-family: 'AppFont', sans-serif; /* Fallback to sans-serif */
}

._text_info_14 {
    font-size: 14px;
}

._text_info_13 {
    font-size: 13px;
}


._split_line {
    border: none;
    height: 1px;
    /* Set the hr color */
    color: #333; /* old IE */
    background-color: #333; /* Modern Browsers */
}

._flex_row {
    display: flex;
    direction: row;
    align-items: center;
}

._flex_row_space {
    display: flex;
    direction: row;
    justify-content: space-between;
}

._flex_column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swal2-container {
    z-index: 9999;
  }

  body .swal2-container {
    z-index: 9999 !important;
  }


 


  .MuiOutlinedInput-root {
    /* Your custom styles here */
    font-family: 'AppFontBook' !important;
  }

  .MuiButton-root {
    font-family: 'AppFontMedium' !important;
  }