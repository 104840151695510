/* FullScreenMessage.css */
.full-screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
    width: 100vw; /* 100% of the viewport width */
    position: fixed; /* Ensure it covers the whole screen */
    top: 0;
    left: 0;
    background-color: #f0f0f0; /* Light grey background */
    color: #333; /* Dark text color */
    font-size: 24px; /* Large font size for visibility */
  }
  
  .message {
    text-align: center;
    padding: 20px;
  }
  